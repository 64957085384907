@import './../../css/autoload.scss';

.form-button-option {

	.label {
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 15px;
	}

	.options {
		@apply grid grid-flow-row-dense gap-8;
		.option {
			font-size: 18px;
			padding: 30px 10px;
			text-align: center;
			border: 2px solid #888;
			border-radius: 30px;
			cursor: pointer;

			&.disabled {
				border: 2px solid #888;
				color: #000;
				opacity: 0.5;
				cursor: not-allowed;
			}

			&.selected.disabled, &.selected {
				color: $themeColor;
				border: 3px solid $themeColor;
				opacity: 1;
				cursor: pointer;
			}
			@apply flex items-center;

			.option-content {
				width: 100%;
				.option-label {
					font-weight: 500;
				}

				.option-sub-label {
					margin-top: 15px;
				}
			}
		}
	}
}
