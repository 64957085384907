@import './../../css/autoload.scss';

.icon-btn {
	@apply flex items-center;
	color: $themeColor;
	cursor: pointer;

	.icon {
		font-size: 22px;
		margin-right: 10px;
		line-height: 0;
	}

	.text {
		font-size: 22px;
	}
}
