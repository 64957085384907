@import './../../css/autoload.scss';

$menuWidth: 250px;

.sticky-menu {
	.sticky {
		top: 135px !important;
	}
}

.step-page-wrapper {
	.ant-steps-item-process .ant-steps-item-icon {
		background: $themeColor;
		border-color: $themeColor;
	}

	.ant-steps-item-finish .ant-steps-item-icon {
		background-color: #fff;
		border-color: $themeColor;

		> .ant-steps-icon {
			color: $themeColor;
		}
	}

	.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon {
		border-color: $themeColor;
	}

	.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon .ant-steps-icon {
		color: $themeColor;
	}

	.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-title, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-subtitle, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-description {
		color: $themeColor;
	}

	.step-page-container {
		@apply flex;
		.step-menu {
			flex: 0 0 $menuWidth;
		}

		.step-page {
			flex: 0 0 calc(100% - #{$menuWidth});

			.next-step {
				background: $themeColor;
				border-radius: 10px;
				font-size: 24px;
				font-weight: bold;
				color: #FFF;
				margin-bottom: 50px;
				cursor: pointer;
				width: 100%;
				padding: 15px;
				text-align: center;
				transition: all 0.5s;

				&.disabled {
					opacity: 0.5;
					cursor: not-allowed;
				}
			}

			.step-session {
				padding: 30px 50px;
				box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
				margin-bottom: 60px;

				.session-title {
					font-size: 35px;
					font-weight: bold;
					padding-bottom: 30px;
					border-bottom: 1px solid #CCC;
					margin-bottom: 50px;

					.sub-title {
						font-size: 24px;
						color: #777;
						font-weight: normal;
					}

					&.has-chip {
						@apply flex items-center;
						.title {
							margin-right: 25px;
						}

						.chip {
							> div {
								background: transparent;
								border: 2px solid #CCC;
								padding-left: 15px;
								padding-right: 15px;
							}
						}
					}
				}

				.input-row {
					@apply grid grid-flow-col gap-8;
					margin-bottom: 50px;

					&.small-margin-bottom {
						margin-bottom: 20px;
					}

					&.no-margin-bottom {
						margin-bottom: 0px;
					}

					&.big-gap {
						@apply gap-14;
					}

					&:last-child {
						margin-bottom: 30px;
					}

					.text-input {

						.input {
							width: 100%;
						}
					}

				}

				&.add-item-control-btn {
					text-align: center;
					color: $themeColor;
					cursor: pointer;

					.session-title{
						color: #000;
						text-align: left;
					}
					.icon {
						font-size: 70px;
						line-height: 1.2;
					}

					.text {
						font-size: 24px;
						font-weight: 500;
					}
				}

				.table-container {
					.no-data {
						font-weight: bold;
						font-size: 32px;
						margin-top: 30px;
						margin-bottom: 60px;
						color: #888;
						text-align: center;
					}

					.data-container {
						margin-bottom: 30px;

						.action-btns {
							@apply flex items-center;
							.action-btn {
								padding: 20px 25px;
								border: #CCC solid 1px;
								margin-right: 30px;
								cursor: pointer;
							}
						}

						.control-header {
							@apply flex items-center;
							height: 75px;

							.search-bar {
								width: 65%;

								.input {
									width: 100%;
								}
							}

							.export-btn {
								border: 2px solid $themeColor;
								border-radius: 10px;
								font-size: 24px;
								font-weight: bold;
								color: $themeColor;
								cursor: pointer;
								padding: 8px 15px;
								width: 25%;

								text-align: center;
								transition: all 0.5s;
								margin-left: auto;
							}
						}

						.table-wrapper {
							table {
								width: 100%;
								text-align: left;
								margin-top: 30px;
								font-size: 18px;

								.checkbox {
									width: 25px;
									height: 25px;
									border: 1px solid #666;
									background: #FFF;
									cursor: pointer;
									margin: auto;

									&.active {
										background: $themeColor;
										color: #FFF;
										position: relative;

										&:before {
											content: '✓';
											@include vertical-center;
											font-size: 22px;

										}
									}
								}

								th {
									padding: 20px 10px;

									&:first-child {
										padding-left: 40px;
									}

									&:last-child {
										padding-right: 40px;
									}
								}

								tr {
									td {
										padding: 20px 10px;
										background: #fff;
										color: #666;

										&:first-child {
											padding-left: 40px;
										}

										&:last-child {
											padding-right: 40px;
										}
									}

									&:nth-child(even) {
										td {

											background: #f4f3f6;
										}
									}
								}
							}

							&.people-table, &.audience-table {
								.status-ball {
									width: 15px;
									height: 15px;
									background: #52c338;
									margin: auto;
									border-radius: 100%;
									margin-top: 5px;
								}

								table {
									th {
										&:nth-child(1) {
											width: 70px;
										}

										&:nth-child(2) {
											width: 76px;
										}
									}

								}

								.name {
									font-weight: bold;
								}

								.last-login-by {
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}
}
