@import './../../../css/autoload.scss';

.add-audience-model {
	position: fixed;
	z-index: 200;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;

	.mask {
		position: absolute;
		background: #000;
		cursor: pointer;
		opacity: 0.5;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	.modal-container {
		z-index: 11;
		position: absolute;
		background: #FFF;
		//cursor: pointer;
		top: 0;
		right: 0;
		width: 60%;
		height: 100%;
		padding: 40px 60px;

		.session-title {
			font-size: 35px;
			font-weight: bold;
			padding-bottom: 30px;
			border-bottom: 1px solid #CCC;
			margin-bottom: 50px;
			position: relative;

			.close-btn {
				position: absolute;
				top: 15px;
				right: 15px;
				cursor: pointer;
				font-size: 20px;
			}
		}

		.input-label {
			font-weight: bold;
			font-size: 18px;
			margin-bottom: 15px;
		}

		.input-row {
			@apply grid grid-flow-col gap-8;
			margin-bottom: 50px;

			&.small-margin-bottom {
				margin-bottom: 20px;
			}

			&.no-margin-bottom {
				margin-bottom: 0px;
			}

			&.big-gap {
				@apply gap-14;
			}

			&:last-child {
				margin-bottom: 30px;
			}

			.text-input {

				.input {
					width: 100%;
				}
			}
		}

		.save-btn {
			border: 2px solid $themeColor;
			border-radius: 10px;
			font-size: 24px;
			font-weight: bold;
			color: $themeColor;
			margin-bottom: 50px;
			cursor: pointer;
			width: 100%;
			padding: 15px;
			text-align: center;
			transition: all 0.5s;

			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}
}

.import-audience-model {

	position: fixed;
	z-index: 200;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;

	.mask {
		position: absolute;
		background: #000;
		cursor: pointer;
		opacity: 0.5;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}


	.modal-container {
		z-index: 11;
		position: absolute;
		background: #FFF;
		//cursor: pointer;
		top: 10%;
		right: 10%;
		width: 80%;
		height: 80%;
		padding: 40px 60px;

		.modal-title {
			font-size: 35px;
			font-weight: bold;
			padding-bottom: 30px;
			border-bottom: 1px solid #CCC;
			margin-bottom: 50px;
			position: relative;
		}

		.file-drop-zone {
			height: calc(100% - 143px);
			@apply flex items-center;

			.file-drop {
				/* relatively position the container bc the contents are absolute */
				position: relative;
				height: 100%;
				width: 100%;
			}

			.file-drop > .file-drop-target {
				/* basic styles */
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: 2px;

				/* horizontally and vertically center all content */
				display: flex;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;

				flex-direction: column;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;

				align-items: center;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;

				justify-content: center;
				-webkit-box-pack: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;

				align-content: center;
				-webkit-align-content: center;
				-ms-flex-line-pack: center;

				text-align: center;
				color: #888;

				.text {
					font-size: 32px;
					font-weight: bold;
				}

				.icon {
					font-size: 150px;
					line-height: 0;
				}
			}

			.file-drop > .file-drop-target.file-drop-dragging-over-frame {
				/* overlay a black mask when dragging over the frame */
				border: none;
				//background-color: rgba(0, 0, 0, 0.65);
				box-shadow: none;
				z-index: 50;
				opacity: 1;

				/* typography */
				//color: white;
			}

			.file-drop > .file-drop-target.file-drop-dragging-over-target {
				/* turn stuff orange when we are dragging over the target */
				color: $themeColor;
				box-shadow: 0 0 13px 3px $themeColor;
			}
		}
	}
}
