@import './../../css/autoload.scss';


.panelist-label {

	font-weight: bold;
	font-size: 18px;
	margin-bottom: 15px;

	.sub {
		font-size: 16px;
		color: #888;
	}
}
