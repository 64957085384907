@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '/node_modules/antd/dist/antd.css';
@import "css/autoload";
@import "assets/fonts/webinar-icon/style.css";
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.ant-steps {
		svg {
			vertical-align: unset;
		}
	}

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

.container {
	@apply mx-auto px-4;
}

.design-theme-option {
	width: 80px;
	height: 80px;
	border-radius: 10px;
	margin: 30px auto;

	&.purple {
		background: #564ea6;
	}

	&.green {
		background: #57bf94;
	}

	&.orange {
		background: #f58461;
	}
}
