
.header-padding {
	height: 85px;
	margin-bottom: 50px;
}

.header-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	padding: 20px;
	box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.15);
	width: 100%;
	background: #FFF;

	.header-container {
		@apply flex items-center;
		.logo {
			img {
				height: 35px;
			}
		}

		.site-name {
			@apply ml-6;
			font-size: 28px;
			font-weight: bold;
		}

		.time {
			@apply ml-auto;
			font-size: 18px;
		}

		.action-btn {
			@apply ml-5;
			cursor: pointer;
			font-size: 30px;

			&.btn-1 {


			}

			&.btn-2 {

			}

			&.btn-3 {
				font-size: 24px;
				color: #CCC;
			}
		}
	}
}
