$break-xs: 767px;
$break-sm: 991px;
$break-md: 1200px;

@mixin respond($size){
  @if $size == xs{
    @media only screen and (max-width: $break-xs){
      @content;
    }
  } @else if $size == sm{
    @media only screen and (max-width: $break-sm){
      @content;
    }
  } @else if $size == md{
    @media only screen and (max-width: $break-md){
      @content;
    }
  } @else{
    @media only screen and (max-width: #{$size}px){
      @content;
    }
  }
}

@mixin background-size($value){
  -webkit-background-size: $value;
  -moz-background-size: $value;
  -ms-background-size: $value;
  -o-background-size: $value;
  background-size: $value;
}

@mixin transition($value){
  -webkit-transition: $value;
  -moz-transition: $value;
  -ms-transition: $value;
  -o-transition: $value;
  transition: $value;
}

@mixin transform($value){
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin animation($value){
  -webkit-animation: $value;
  -moz-animation: $value;
  -ms-animation: $value;
  -o-animation: $value;
  animation: $value;
}
@mixin animation-delay($value){
  -webkit-animation-delay: $value;
  -moz-animation-delay: $value;
  -o-animation-delay: $value;
  animation-delay: $value;
}
@mixin background-cover(){
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@mixin transform-left-50(){
  @include transform(translate(-50%));
}
@mixin transform-top-50(){
  @include transform(translate(0,-50%));
}
@mixin transform-top-left-50(){
  @include transform(translate(-50%,-50%));
}

@mixin vertical-center(){
  position: absolute;
  top : 50%;
  left: 50%;
  @include transform(translate(-50%,-50%));
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

