@font-face {
  font-family: 'webinar-icon';
  src:  url('fonts/webinar-icon.eot?wsoap1');
  src:  url('fonts/webinar-icon.eot?wsoap1#iefix') format('embedded-opentype'),
    url('fonts/webinar-icon.ttf?wsoap1') format('truetype'),
    url('fonts/webinar-icon.woff?wsoap1') format('woff'),
    url('fonts/webinar-icon.svg?wsoap1#webinar-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="webinar-icon-"], [class*=" webinar-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webinar-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.webinar-icon-send:before {
  content: "\e90a";
}
.webinar-icon-search:before {
  content: "\e909";
}
.webinar-icon-upload:before {
  content: "\e908";
}
.webinar-icon-download:before {
  content: "\e907";
}
.webinar-icon-close:before {
  content: "\e906";
}
.webinar-icon-list:before {
  content: "\e905";
}
.webinar-icon-delete:before {
  content: "\e903";
}
.webinar-icon-add:before {
  content: "\e904";
}
.webinar-icon-circle:before {
  content: "\e900";
}
.webinar-icon-lock:before {
  content: "\e901";
}
.webinar-icon-eye:before {
  content: "\e902";
}
